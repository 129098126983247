import React from 'react';
import parse from 'html-react-parser';
import { IAppComponentProps } from "../resources/component-interface";
interface IComponentProps extends IAppComponentProps  {
    cmsBlockContent: string | null
}

const CmsBlock: React.FC<IComponentProps> = ({ className = "", style = {}, cmsBlockContent }) => {

    if(!cmsBlockContent) {
        return (
            <></>
        );
    }

    return (
        <div className={className + "__cmsBlock"}>{parse(cmsBlockContent)}</div>
    );
};

export default CmsBlock;
