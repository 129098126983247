/*
 * Copyright © MageSpecialist - Skeeller srl. All rights reserved.
 * See COPYING.txt for license details.
 */
const pDE = {
    'Hi %1': 'Hi %1',
    'Sign in | Register': 'Anmelden | Registrieren',
    'Sign in': 'Anmelden',
    'Register': 'Registrieren',
    'Start your search': 'Starten Sie Ihre Suche',
    'Search ...': 'Suche ...',
};

export default pDE;
