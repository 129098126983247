import {useContext, useEffect, useState} from "react";
import {AppConfigContext} from "../../context/AppConfigProvider";
import { useQuery } from '@apollo/client';
import { useSessionStorage } from 'usehooks-ts'
import gql from "graphql-tag";


const useCmsBlock = (cmsBlockId:string) => {
    const { config } = useContext(AppConfigContext);
    const [cachedBlock, setCachedBlock] = useSessionStorage<string|null>('cmsBlock__' + config.storeCode + cmsBlockId, '');
    const isCached = cachedBlock !== '';

    const categoryRootPathQuery = gql`
      query cmsBlock($blockId: String!) {
       cmsBlocks(identifiers: [$blockId]) {
            items {
              identifier
              title
              content
            }
          }
      }
    `

    const cmsBlockData = useQuery(categoryRootPathQuery, {
        variables: { blockId: cmsBlockId },
        skip: isCached
    });

    useEffect(() => {
       if(cmsBlockData.error) {
           setCachedBlock(null);
           return;
       }

        if(cmsBlockData.data) {
            const {cmsBlocks: {items = {}} = {}} = cmsBlockData.data;

            if(Object.values(items).length === 0) {
                setCachedBlock(null);
                return;
            }

            const cmsBlock = Object.values(items).length >= 1 ? items[0] : {}
            const {content = ''} = cmsBlock || {};

            if(content !== '') {
                setCachedBlock(content);
            }
        }
    }, [cmsBlockData, setCachedBlock]);

    return cachedBlock || '';
};

export default useCmsBlock;
