import {IAppComponentProps} from "../../resources/component-interface";
import {ICategory, ITreeNode} from "../../interface/categories-interface";
import Menu from "./index";
import React, {useContext} from "react";
import {AppConfigContext} from "../../context/AppConfigProvider";
import {CategoriesContext} from "../../context/CategoriesProvider";

interface IComponentProps extends IAppComponentProps  {
}

const MenuItem: React.FC<IComponentProps> = ({ className = "", style = {}}) => {
    const { categories } = useContext(CategoriesContext);
    const { config } = useContext(AppConfigContext);
    const { storeCode, context } = config;

    return (
        <>
            {categories && categories
                .filter((category:ICategory) =>
                    category.include_in_menu === 1
                )
                .map((category: ICategory, index: any) => {
                    const linkUrl = context === 'wordpress' ?
                        config.baseUrl + `${storeCode}/` + category.url_path + config.catalogUrlSuffix :
                        config.baseUrl + category.url_path + config.catalogUrlSuffix;
                    return (
                        <div className={className + "Item"} key={index}>
                            <a className={className + "Link"} href={linkUrl} >
                                <span>{category.name}</span>
                            </a>
                        </div>
                    );
                })
            }
        </>
    );
}

export default MenuItem;
