import React from "react";

function Extr4UserIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 19 24"
            xmlSpace="preserve"
            width="19"
            height="24"
        >
            <defs><clipPath id="clippath"><rect className="cls-2" fill="none" stroke-width="0px" width="19" height="24"/></clipPath></defs><g id="Livello_1-2"><g id="Raggruppa_3931"><g className="cls-3" clip-path="url(#clippath)"><g id="Raggruppa_3930"><path id="Tracciato_3300" className="cls-1" fill="#000" stroke-width="0px" d="M19,23.3h-1.17v-5.24c0-1.61-1.3-2.91-2.91-2.91H4.44c-1.61,0-2.91,1.3-2.91,2.91v5.24H.36v-5.24c0-2.25,1.83-4.08,4.08-4.08h10.49c2.25,0,4.08,1.83,4.08,4.08v5.24Z"/><path id="Tracciato_3301" className="cls-1" fill="#000" stroke-width="0px" d="M9.68,12.82c-3.54,0-6.41-2.87-6.41-6.41S6.14,0,9.68,0s6.41,2.87,6.41,6.41h0c0,3.54-2.87,6.41-6.41,6.41M9.68,1.17c-2.9,0-5.24,2.35-5.24,5.24s2.35,5.24,5.24,5.24,5.24-2.35,5.24-5.24S12.57,1.17,9.68,1.17"/></g></g></g></g>
        </svg>
    );
}

export default Extr4UserIcon;
