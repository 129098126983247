/*
 * Copyright © MageSpecialist - Skeeller srl. All rights reserved.
 * See COPYING.txt for license details.
 */
const pFR = {
    'Hi %1': 'Hi %1',
    'Sign in | Register': 'Sign in | Register',
    'Sign in': 'Sign in',
    'Register': 'Register',
    'Start your search': 'Commencez votre recherche',
    'Search ...': 'Rechercer ...',
};

export default pFR;
