/*
 * Copyright © MageSpecialist - Skeeller srl. All rights reserved.
 * See COPYING.txt for license details.
 */
const pRU = {
    'Hi %1': 'Hi %1',
    'Sign in | Register': 'Sign in | Register',
    'Sign in': 'Sign in',
    'Register': 'Register',
    'Start your search': 'Начните поиск',
    'Search ...': 'Поиск ...',
};

export default pRU;
