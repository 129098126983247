import React, {useContext} from "react";
import useCmsBlock from "../lib/hooks/use-cms-block";
import {AppConfigContext} from "./AppConfigProvider";

interface IContextProps {
    cmsBlockBefore: string,
    cmsBlockAfter: string,
    cmsBlockMessage: string,
}

export const CmsBlocksContext = React.createContext<IContextProps>({} as IContextProps);

    const CmsBlocksProvider = (props: any) => {
    const { config } = useContext(AppConfigContext);
    const {beforeCmsBlock = '',afterCmsBlock = '', messageCmsBlock = ''} = config;
    const cmsBlockBefore = useCmsBlock(beforeCmsBlock);
    const cmsBlockAfter = useCmsBlock(afterCmsBlock)
    const cmsBlockMessage = useCmsBlock(messageCmsBlock)

    return (
        <CmsBlocksContext.Provider
            value={{
                cmsBlockBefore,
                cmsBlockAfter,
                cmsBlockMessage
            }}
        >
            {props.children}
        </CmsBlocksContext.Provider>
    );
};
export default CmsBlocksProvider;
