import React, {useCallback, useState, useEffect} from 'react';
import './App.scss';
import AppConfigProvider, {IAppConfig} from "./context/AppConfigProvider";
import LocaleProvider from "./components/Locale/LocaleProvider";
import LayoutMenu from "./components/LayoutMenu";
import LayoutHeader from "./components/LayoutHeader";
import CategoriesProvider from "./context/CategoriesProvider";
import CmsBlocksProvider from "./context/CmsBlocksProvider";
import {ApolloProvider, ApolloClient, InMemoryCache} from "@apollo/client";

interface IAppProps {
    config: IAppConfig
}
const App: React.FC<IAppProps> = ({ config}) => {
    const {cssThemeClass} = config;
    const [isOpenMenu, setIsOpenMenu] = useState(false);

    const handlOpen = useCallback(() => {
            setIsOpenMenu(true);
        }
        , [setIsOpenMenu])

    const handlClose = useCallback(() => {
            setIsOpenMenu(false);
        }
        , [setIsOpenMenu])

    const cache = new InMemoryCache();

    const client = new ApolloClient({
        uri: config.baseUrl + 'graphql',
        cache: cache,
        headers: {
            store: config.storeCode
        }
    })

    useEffect(() => {
        window.addEventListener("scroll", () => {
            const headerElement = document.getElementsByClassName("page-wrapper");
            if(headerElement && headerElement.length){
                if(window.scrollY == 0){
                    headerElement[0].classList.remove("header-fixed")
                    return
                }

                if(!headerElement[0].classList.contains("header-fixed")){
                    headerElement[0].classList.add("header-fixed")
                }
            }
        })
    },[])

    return (
      <AppConfigProvider config={config}>
          <ApolloProvider client={client}>
              <LocaleProvider>
                  <CategoriesProvider>
                      <CmsBlocksProvider>
                          <LayoutMenu className={cssThemeClass} onClick={handlClose} isOpen={isOpenMenu} />
                          <LayoutHeader className="ImacHeader" onClick={handlOpen}/>
                      </CmsBlocksProvider>
                  </CategoriesProvider>
              </LocaleProvider>
          </ApolloProvider>
      </AppConfigProvider>
    )
}

export default App;
