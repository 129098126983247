import React, {useContext, useEffect} from "react";
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { useSessionStorage } from 'usehooks-ts'
import {ICategory, ITreeNode} from "../interface/categories-interface";
import {AppConfigContext, IAppConfig} from "./AppConfigProvider";

interface IContextProps {
    categories: any
}

export const CategoriesContext = React.createContext<IContextProps>({

} as IContextProps);
const CategoriesProvider = (props: any) => {
    const { config } = useContext(AppConfigContext);
    const CACHE_KEY_MENU = 'imac__cache_key__menu_' + config.storeCode;

    const [cachedCategories, setCachedCategorie] = useSessionStorage<ITreeNode[]>(CACHE_KEY_MENU, [])

    const isCached = cachedCategories.length > 0;

    useEffect(() => {
        console.log('menu cache: ', isCached)
    }, [isCached])

    const categories:any[] = [];

    const categoriesQuery = gql`
        {
        storeConfig {
            root_category_uid
        }

        categories {
            items {
                uid
                name
                children_count
                children {
                    include_in_menu
                    uid
                    name
                    path
                    url_path
                    children {
                        include_in_menu
                        uid
                        name
                        path
                        url_path
                        children {
                            include_in_menu
                            uid
                            name
                            path
                            url_path
                        }
                    }
                }
            }
        }
    }
        `

    const categoriesData = useQuery(categoriesQuery, {
        skip: isCached
    });

    useEffect(() => {
        if(categoriesData.data){
            const { data: { storeConfig, categories: { items: categories } } } = categoriesData;
            const { root_category_uid: rootCategoryUid } = storeConfig;
            const rootCategory = categories.find((c:any) => c.uid === rootCategoryUid )
            const { children: rootCategories } = rootCategory;

            setCachedCategorie(rootCategories);
        }

    }, [categoriesData, setCachedCategorie])

    return (
        <CategoriesContext.Provider
            value={{
                categories: cachedCategories
            }}
            >
            {props.children}
        </CategoriesContext.Provider>
    );
};
export default CategoriesProvider;
