import React from "react";

const Extr4CartIcon = ({ ...props }) => (
        <svg
            {...props}
            id="Livello_2"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 23.15 23.3"
            xmlSpace="preserve"
            width="24"
            height="24"
        >
            <defs>
                <clipPath id="clippath"><rect className="cls-2" fill="none" stroke-width="0px" width="23.15" height="23.3"/></clipPath></defs><g id="Livello_1-2"><g id="Raggruppa_3929"><g className="cls-3" clip-path="url(#clippath)"><g id="Raggruppa_3928"><path id="Tracciato_3299" className="cls-1" fill="#000" stroke-width="0px" d="M21.98,5.83h-5.75v-1.17C16.2,2.09,14.08.03,11.51.07c-2.52.04-4.56,2.07-4.59,4.59v1.17H1.17C.52,5.82,0,6.35,0,6.99h0v.08l.87,12.97c.13,1.83,1.65,3.26,3.49,3.26h14.43c1.84,0,3.36-1.43,3.49-3.26l.86-12.97c.04-.64-.44-1.2-1.09-1.24h-.08M8.08,4.66c.1-1.93,1.74-3.42,3.67-3.32,1.79.09,3.23,1.52,3.32,3.32v1.17h-6.99v-1.17ZM18.79,22.14H4.36c-1.23,0-2.24-.95-2.33-2.17v-.16s19.1,0,19.1,0v.16c-.09,1.22-1.11,2.17-2.34,2.17M21.2,18.64H1.94l-.78-11.65h5.75v1.16c.01.32.28.57.61.56.3-.01.55-.26.56-.56v-1.16h6.99v1.16c.01.32.28.57.61.56.3-.01.55-.26.56-.56v-1.16h5.74l-.78,11.65Z"/></g></g></g></g>
        </svg>
)

export default Extr4CartIcon;
