import React, {useCallback, useContext, useEffect, useState} from 'react';
import { IAppComponentProps } from "../resources/component-interface";
import {AppConfigContext} from "../context/AppConfigProvider";
import LogoPrimigi from "./Icon/LogoPrimigi";
import LogoIgi from "./Icon/LogoIgi";
import HamburgerIcon from "./Icon/HamburgerIcon";
import CartIcon from "./Icon/CartIcon";
import WishlistIcon from "./Icon/WishlistIcon";
import {LocaleContext} from "./Locale/LocaleProvider";
import configUrl from "../lib/config-url";
import StoreSwitcher from "./StoreSwitcher";
import MenuHeader from "./Menu/MenuHeader";
import {CmsBlocksContext} from "../context/CmsBlocksProvider";
import CmsBlock from "./CmsBlock";
import Search from "./Search";

import Extr4CartIcon from "./Icon/Extr4CartIcon";
import Extr4HamburgerIcon from "./Icon/Extr4HamburgerIcon";
import Extr4Logo from "./Icon/Extr4Logo";
import Extr4UserIcon from "./Icon/Extr4UserIcon";
import Extr4WishlistIcon from "./Icon/Extr4WishlistIcon";

interface IComponentProps extends IAppComponentProps  {
    onClick: () => void,
}

const LayoutHeader: React.FC<IComponentProps> = ({ className = "", style = {}, onClick }) => {
    const { config } = useContext(AppConfigContext);
    const { cmsBlockMessage } = useContext(CmsBlocksContext);
    const [cartSummaryCount, setCartSummaryCount] = useState<number>(0);
    const [customerFirstName, setCustomerFirstName] = useState<string>('');
    const { t } = useContext(LocaleContext);
    const containerClass = className + ' ' + config.cssThemeClass
    const isIgiCo = config.cssThemeClass === 'theme-class--igi';
    const isExtr4 = config.cssThemeClass === 'theme-class--extr4';
    
    const handleClick = useCallback(() => {
        onClick && onClick();
    }, [onClick]);

    useEffect(() => {
        // Enable checks for magento context
        if(config?.context === 'magento') {
            const handleStorage = () => {
                if (window?.localStorage['mage-cache-storage']) {
                    const mageCacheStorage = JSON.parse(window.localStorage['mage-cache-storage']);
                    setCartSummaryCount(mageCacheStorage?.cart?.summary_count || 0);
                    if(mageCacheStorage?.customer) {
                        setCustomerFirstName(mageCacheStorage?.customer?.firstname || '');
                    }
                }
            }

            // Adds setInterval because deps in useEffect can't see Magento localStorage changes
            const timerId = setInterval(handleStorage, 1000);
            handleStorage();

            return () => {
                clearInterval(timerId);
            };
        }
    }, []);

    return (
        isExtr4 ? 
            <div className={containerClass} >
                {cmsBlockMessage && <div className={className + "__message"}>
                    <CmsBlock className={className} cmsBlockContent={cmsBlockMessage} />
                </div>
                }
                <div className={className + "__headerWrarpper"}>
                    <div className={className + "__left"}>
                        <div className={className + "__hamburger"} onClick={handleClick}>
                            <div className="ImacIcon ImacIcon--bg-transparent">
                                <Extr4HamburgerIcon />
                            </div>
                        </div>
                        <div className={className + "__menuWrapper"}>
                            <MenuHeader className={className + "__Menu"} />    
                        </div>
                    </div>
                    <div className={className + "__center"}>
                        <div className={className + "__logo"}>
                            <a href={config.logoBaseUrl} >
                                <Extr4Logo className={className + "__logoImage"} />
                            </a>
                        </div>
                    </div>
                    <div className={className + "__right"}>
                        <Search className={className + "__search"}/>
                        {!config.hasHiddenWishlist &&
                            <div className="ImacIcon ImacIcon--bg-transparent ImacIcon--wishlistIcon imac-my-6">
                                <a href={
                                    config.context === 'wordpress' ?
                                    config.baseUrl + `${config.storeCode}/` + configUrl.wishlist :
                                    config.baseUrl + configUrl.wishlist
                                }>
                                    <Extr4WishlistIcon />
                                </a>
                            </div>
                        }
                        {!config.hasHiddenCart &&
                            <div className="ImacIcon ImacIcon--bg-transparent ImacIcon--cartIcon imac-my-6">
                                {cartSummaryCount > 0 ? <div className="ImacCartSummary">{cartSummaryCount}</div> : <></>}
                                <a href={
                                    config.context === 'wordpress' ?
                                    config.baseUrl + `${config.storeCode}/` + configUrl.cart :
                                    config.baseUrl + configUrl.cart
                                }>
                                    <Extr4CartIcon />
                                </a>
                            </div>
                        }
                        {!config.hasHiddenRegistration &&
                            <div className="ImacIcon ImacIcon--bg-transparent ImacIcon--userIcon imac-my-6">
                                <a href={
                                    config.context === 'wordpress' ?
                                    config.baseUrl + `${config.storeCode}/` + configUrl.account :
                                    config.baseUrl + configUrl.account
                                }>
                                    <Extr4UserIcon />
                                </a>
                            </div>
                        }
                    </div>
                </div>

            </div>
        : 
            <div className={containerClass} >
                {cmsBlockMessage && <div className={className + "__message"}>
                    <CmsBlock className={className} cmsBlockContent={cmsBlockMessage} />
                </div>
                }
                <div className={className + "__headerWrarpper"}>
                    <div className={className + "__left"}>
                        <div className={className + "__hamburger"} onClick={handleClick}>
                            <div className="ImacIcon ImacIcon--bg-transperent ImacIcon--igi-bg-blue ImacIcon--color-black">
                                <HamburgerIcon />
                            </div>
                        </div>
                        <div className={className + "__logo"}>
                            <a href={config.logoBaseUrl} >
                                {!isIgiCo && <LogoPrimigi className={className + "__logoImage"} />}
                                {isIgiCo && <LogoIgi className={className + "__logoImageIgi LogoIgiCo"} />}
                            </a>
                        </div>
                    </div>
                    <div className={className + "__center"}>
                       <MenuHeader className={className + "__Menu"} />
                    </div>
                    <div className={className + "__right"}>
                        <Search className={className + "__search"}/>
                        
                        {!config.hasHiddenCart &&
                            <div className="ImacIcon ImacIcon--bg-transperent ImacIcon--igi-bg-green ImacIcon--color-black imac-my-6">
                                {cartSummaryCount > 0 ? <div className="ImacCartSummary">{cartSummaryCount}</div> : <></>}
                                <a href={
                                    config.context === 'wordpress' ?
                                    config.baseUrl + `${config.storeCode}/` + configUrl.cart :
                                    config.baseUrl + configUrl.cart
                                }>
                                    <CartIcon />
                                </a>
                            </div>
                        }
                        {!config.hasHiddenWishlist &&
                            <div className="ImacIcon ImacIcon--bg-transperent ImacIcon--igi-bg-green imac-my-6 ImacIcon--color-black ">
                                <a href={
                                    config.context === 'wordpress' ?
                                    config.baseUrl + `${config.storeCode}/` + configUrl.wishlist :
                                    config.baseUrl + configUrl.wishlist
                                }>
                                    <WishlistIcon />
                                </a>
                            </div>
                        }
                        {!config.hasHiddenRegistration &&
                            <div className={className + "__HeaderLogin imac-my-6"}>
                                <div className={className + "__HeaderTitle"}>
                                    {isIgiCo && t('My Igi&Co')}
                                    {!isIgiCo && t('My Primigi')}
                                </div>
                                <div className={className + "__HeaderLink"}>
                                    <a href={
                                        config.context === 'wordpress' ?
                                        config.baseUrl + `${config.storeCode}/` + configUrl.account :
                                        config.baseUrl + configUrl.account
                                    }>{customerFirstName ? (t('Hi %1', [customerFirstName])) : t('Sign in')}</a>
                                    {!customerFirstName &&
                                        <>
                                            {" | "}
                                            <a href={
                                                config.context === 'wordpress' ?
                                                config.baseUrl + `${config.storeCode}/` + configUrl.accountCreate :
                                                config.baseUrl + configUrl.accountCreate
                                            }>{t('Register')}</a>
                                        </>
                                    }
                                </div>
                            </div>
                        }
                         
                        <div className={className + "__storeSwitcherContainer imac-my-6" }>
                            <StoreSwitcher className={className + "__storeSwitcher"} />
                        </div>
                    </div>
                </div>

            </div>
        
        
    );
};

export default LayoutHeader;
