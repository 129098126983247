import React from "react";

function Extr4HamburgerIcon() {
    return (
        <svg
            id="Livello_2"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 25.1 24.46"
            xmlSpace="preserve"
            width="25"
            height="24"
        >
           <defs></defs><g id="Livello_1-2"><g id="Raggruppa_3936">
                <rect id="Rettangolo_2319" className="cls-1" fill="#000" stroke-width="0px" width="13.45" height="1.32"/>
                <rect id="Rettangolo_2320" className="cls-1" fill="#000" stroke-width="0px" y="11.57" width="25.1" height="1.32"/>
                <rect id="Rettangolo_2321" className="cls-1" fill="#000" stroke-width="0px" y="23.13" width="13.45" height="1.32"/></g></g>
        </svg>
    );
}

export default Extr4HamburgerIcon;
