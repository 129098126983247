import React, {useCallback, useContext, useEffect} from 'react';
import { IAppComponentProps } from "../resources/component-interface";
import Menu from "./Menu";
import CmsBlock from "./CmsBlock";
import {AppConfigContext} from "../context/AppConfigProvider";
import initializeAccordion from "../lib/accordion";
import Header from "./Header";
import LogoPrimigi from "./Icon/LogoPrimigi";
import LogoIgi from "./Icon/LogoIgi";
import {CategoriesContext} from "../context/CategoriesProvider";
import {CmsBlocksContext} from "../context/CmsBlocksProvider";
import Extr4MenuLogo from "./Icon/Extr4MenuLogo";

interface IComponentProps extends IAppComponentProps  {
    onClick: () => void,
    isOpen: boolean,
}

const LayoutMenu: React.FC<IComponentProps> = ({ className = "", style = {}, onClick, isOpen }) => {
    const { config } = useContext(AppConfigContext);
    const { categories } = useContext(CategoriesContext);
    const { cmsBlockBefore, cmsBlockAfter } = useContext(CmsBlocksContext);
    const { cssThemeClass } = config;
    const isIgiCo = cssThemeClass === 'theme-class--igi';
    const isExtr4 = config.cssThemeClass === 'theme-class--extr4';

    const menuClass = "ImacMenu";

    const imacMenuContainerClass = isOpen ? 'ImacMenuContainer open ' + cssThemeClass: 'ImacMenuContainer ' + cssThemeClass;
    const imacOverlayClass = isOpen ? 'imac-overlay-open-bg visible ' + cssThemeClass: 'imac-overlay-open-bg ' + cssThemeClass;

    const handleClick = useCallback(() => {
        onClick && onClick();
    }, [onClick]);

    useEffect(() => {
        if(cmsBlockAfter) {
            // seleziona tutti gli elementi con la classe "parent"
            initializeAccordion();
        }
    }, [cmsBlockAfter])

    return (
        <>
            <div className={imacOverlayClass} onClick={handleClick}></div>
            <div className={imacMenuContainerClass}>
                <span className="ImacMenuContainer__close" onClick={handleClick}></span>
                <div className={className} >
                    {isExtr4 ? 
                        <div className={menuClass + "__Logo"}>
                            <a href={config.logoBaseUrl} >
                                <Extr4MenuLogo />
                            </a>
                        </div>
                    :
                        <div className={menuClass + "__Logo"}>
                            <a href={config.logoBaseUrl} >
                                {!isIgiCo && <LogoPrimigi />}
                                {isIgiCo && <LogoIgi className="LogoIgiCo" style={{width:"170px",height:"60px"}}/>}
                            </a>
                        </div>
                    }
                    {!isExtr4 && <Header className={menuClass} />}
                    <CmsBlock className={menuClass} cmsBlockContent={cmsBlockBefore} />
                    <Menu className={menuClass} categories={categories} />
                    <CmsBlock className={menuClass} cmsBlockContent={cmsBlockAfter}/>
                </div>
            </div>
        </>

    );
};

export default LayoutMenu;
