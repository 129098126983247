import { IAppComponentProps } from "../../resources/component-interface";
import { ICategory } from "../../interface/categories-interface";
import React, {useContext} from "react";
import MenuSubmenuSecondLevel from "./MenuSubmenuSecondLevel";
import {AppConfigContext} from "../../context/AppConfigProvider";

interface IComponentProps extends IAppComponentProps {
    currentItem: ICategory;
    isOpen: boolean;
    onClick: () => void;
}

const MenuSubmenuFirstLevel: React.FC<IComponentProps> = ({
                                                              className = "",
                                                              style = {},
                                                              currentItem,
                                                              isOpen,
                                                              onClick,
                                                          }) => {
    const { config } = useContext(AppConfigContext);
    const children = currentItem.children;
    const hasChildren = children && children.length > 0;
    const hasChildClass = hasChildren ? " hasChild" : "";
    const subClass = className + "__submenu" + hasChildClass;
    const { storeCode, context } = config;

    const linkUrl = context === 'wordpress' ?
        config.baseUrl + `${storeCode}/` + currentItem.url_path + config.catalogUrlSuffix :
        config.baseUrl + currentItem.url_path + config.catalogUrlSuffix;

    const parentClass = isOpen
        ? className + "__parentLink open" + hasChildClass
        : className + "__parentLink" + hasChildClass;
    const childrenClass = isOpen
        ? className + "__childrenContainer open"
        : className + "__childrenContainer";

    return (
        <div className={subClass} style={style}>
            {hasChildren && (
                <div className={parentClass} onClick={onClick}>
                    {currentItem.name}
                </div>
            )}
            {!hasChildren && (
                <div className={parentClass}>
                    <a href={linkUrl}>{currentItem.name}</a>
                </div>
            )}
            {hasChildren && isOpen && (
                <div className={childrenClass}>
                    {children &&
                        children
                            .filter((category: ICategory) => category.include_in_menu === 1)
                            .map((category: ICategory) => {
                                return (
                                    <MenuSubmenuSecondLevel
                                        key={category.uid}
                                        className={className}
                                        currentItem={category}
                                    />
                                );
                            })}
                </div>
            )}
        </div>
    );
};

export default MenuSubmenuFirstLevel;
