import React, {useContext} from 'react';
import { IAppComponentProps } from "../resources/component-interface";
import {AppConfigContext} from "../context/AppConfigProvider";
import {LocaleContext} from "../components/Locale/LocaleProvider";
import configUrl from "../lib/config-url";
import StoreSwitcher from "./StoreSwitcher";
interface IComponentProps extends IAppComponentProps  {}

const Header: React.FC<IComponentProps> = ({ className = "", style = {} }) => {
    const { config } = useContext(AppConfigContext);
    const isIgiCo = config.cssThemeClass === 'theme-class--igi';
    const { t } = useContext(LocaleContext);

    const stores = [
        {name: 'IT', code: 'p_it'},
        {name: 'ES', code: 'p_es'},
        {name: 'EN', code: 'p_en'},
        {name: 'DE', code: 'p_de'}
    ]
    const handleChange = (e:any) => {
        window.location.href = e.target.value;
    }

    return (
        <div className={className + "__HeaderContainer"}>
            <div className={className + "__Header"}>
                {!config.hasHiddenRegistration &&
                    <div className={className + "__HeaderLogin"}>
                        <div className={className + "__HeaderTitle"}>
                            {isIgiCo && t('My Igi&Co')}
                            {!isIgiCo && t('My Primigi')}
                        </div>
                        <div className={className + "__HeaderLink"}>
                            <a href={config.context === 'wordpress' ?
                                config.baseUrl + `${config.storeCode}/` + configUrl.account :
                                config.baseUrl + configUrl.account}>{t('Sign in')}</a>&nbsp;|&nbsp;
                            <a href={config.context === 'wordpress' ?
                                config.baseUrl + `${config.storeCode}/` + configUrl.accountCreate :
                                config.baseUrl + configUrl.accountCreate}>{t('Register')}</a>
                        </div>
                    </div>
                }
                <div className={className + "__HeaderLink"}>
                    <StoreSwitcher className={className + "__HeaderSelect"} />
                </div>
            </div>
            <div className={className + "__MenuTitle"}>
                {t('Menu')}
            </div>
        </div>

    );
};

export default Header;
