import {IAppComponentProps} from "../../resources/component-interface";
import {ICategory, ITreeNode} from "../../interface/categories-interface";
import Menu from "./index";
import React, {useContext} from "react";
import {AppConfigContext} from "../../context/AppConfigProvider";

interface IComponentProps extends IAppComponentProps  {
    currentItem: ICategory
}

const MenuItem: React.FC<IComponentProps> = ({ className = "", style = {} , currentItem}) => {
    const { config } = useContext(AppConfigContext);
    const { context, storeCode } = config;
    const itemClass = className + "__itemMenu";

    const linkUrl = context === 'wordpress' ?
        config.baseUrl + `${storeCode}/` + currentItem.url_path + config.catalogUrlSuffix :
        config.baseUrl + currentItem.url_path + config.catalogUrlSuffix;

    return (
        <div className={itemClass} style={style}>
            <a href={linkUrl} className={className + "__link"}>
                <span>{currentItem.name}</span>
            </a>
        </div>
    );
}

export default MenuItem;
