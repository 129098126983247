import React from "react";

function HamburgerIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 450 450"
            version="1.1"
            viewBox="0 0 450 450"
            xmlSpace="preserve"
            fill="currentColor"
            width="auto"
            height="25"
        >
            <path d="M426.7 201.7H23.3C10.5 201.7 0 212.1 0 225c0 12.8 10.5 23.3 23.3 23.3h403.4c12.8 0 23.3-10.5 23.3-23.3 0-12.9-10.5-23.3-23.3-23.3zM23.3 98.2h403.4c12.8 0 23.3-10.5 23.3-23.3s-10.5-23.3-23.3-23.3H23.3C10.5 51.6 0 62.1 0 74.9s10.5 23.3 23.3 23.3zM426.7 351.8H23.3C10.5 351.8 0 362.3 0 375.1s10.5 23.3 23.3 23.3h403.4c12.8 0 23.3-10.5 23.3-23.3s-10.5-23.3-23.3-23.3z"></path>
        </svg>
    );
}

export default HamburgerIcon;
