import {IAppComponentProps} from "../../resources/component-interface";
import {ICategory, ITreeNode} from "../../interface/categories-interface";
import React, {useContext, useState} from "react";
import MenuItem from "./MenuItem";
import styles from "./Menu.module.scss";
import {AppConfigContext} from "../../context/AppConfigProvider";

interface IComponentProps extends IAppComponentProps  {
    currentItem: ICategory
}

const MenuSubmenuSecondLevel: React.FC<IComponentProps> = ({ className = "", style = {} , currentItem}) => {
    const { config } = useContext(AppConfigContext);
    const { storeCode, context } = config;

    const linkUrl = context === 'wordpress' ?
        config.baseUrl + `${storeCode}/` + currentItem.url_path + config.catalogUrlSuffix :
        config.baseUrl + currentItem.url_path + config.catalogUrlSuffix;

    const children = currentItem.children;
    const hasChildren = children!.length > 0;
    const hasChildClass = hasChildren ? ' hasChild' : '';
    const subClass = className + "__submenu" + hasChildClass;

    const [isOpen, setIsOpen] = useState(false);
    const parentClass = isOpen ?
        className + "__parentLink open" + hasChildClass :
        className + "__parentLink" + hasChildClass;
    const childrenClass = isOpen ? className + "__childrenContainer open" : className + '__childrenContainer';

    const handleClick = () => {
        setIsOpen(!isOpen);
    }

    return (
        <div className={subClass} style={style}>
                {hasChildren && <div className={parentClass} onClick={handleClick}>{currentItem.name}</div>}
                {!hasChildren && <div className={parentClass} >
                    <a href={linkUrl}>
                        {currentItem.name}
                    </a>
                </div>}

                {hasChildren && isOpen &&
                    <div className={childrenClass}>
                        {children && children.filter((category:ICategory) =>
                            category.include_in_menu === 1
                        )
                            .map((category:ICategory) => {
                                return (
                                    <MenuItem key={category.uid} className={className} currentItem={category} />
                                )
                            })
                        }
                    </div>
                }
        </div>
    );
}

export default MenuSubmenuSecondLevel;
