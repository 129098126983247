import { IAppComponentProps } from "../../resources/component-interface";
import { ICategory } from "../../interface/categories-interface";
import React, { useState } from "react";
import MenuSubmenuFirstLevel from "./MenuSubmenuFirstLevel";

interface IComponentProps extends IAppComponentProps {
    categories: ICategory[] | null;
}

const Menu: React.FC<IComponentProps> = ({
                                             className = "",
                                             style = {},
                                             categories,
                                         }) => {
    const navClass = className + "--border";
    const [openIndex, setOpenIndex] = useState<number | null>(null);

    const handleClick = (index: number) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <nav className={className + " " + navClass} style={style}>
            {categories &&
                categories
                    .filter((category: ICategory) => category.include_in_menu === 1)
                    .map((category: ICategory, index: number) => {
                        const isOpen = openIndex === index;
                        return (
                            <MenuSubmenuFirstLevel
                                key={category.uid}
                                className={className}
                                currentItem={category}
                                isOpen={isOpen}
                                onClick={() => handleClick(index)}
                            />
                        );
                    })}
        </nav>
    );
};

export default Menu;
