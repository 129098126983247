import React, {useContext} from "react";
import {AppConfigContext} from "../context/AppConfigProvider";
import { IAppComponentProps } from "../resources/component-interface";
import useLocalization from "../lib/hooks/use-localization";
import ArrowIcon from "./Icon/ArrowIcon";
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

interface IComponentProps extends IAppComponentProps  {
}
const StoreSwitcher: React.FC<IComponentProps> = ({ className = "", style = {} }) => {
    const { config } = useContext(AppConfigContext);
    const { cssThemeClass } = config;
    const { locale } = config;
    const localeCode = locale.substring(0,2);
    const localizationMap = useLocalization();

    return (
        <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>
                <button className="IconButton" aria-label="Customise options">
                    <span>{localeCode}</span> <ArrowIcon className="IconArrow" />
                </button>
            </DropdownMenu.Trigger>

            <DropdownMenu.Portal >
                <DropdownMenu.Content className={"DropdownMenuContent " + cssThemeClass} sideOffset={5}>
                    {
                        Object.keys(localizationMap)
                            .map((key, index) => {
                                return (
                                    <DropdownMenu.Item className="DropdownMenuItem" key={index}>
                                        <a href={localizationMap[key]}>{key.toUpperCase()}</a>
                                    </DropdownMenu.Item>
                                )
                            }
                        )
                    }
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
        </DropdownMenu.Root>
    );
};

export default StoreSwitcher;
