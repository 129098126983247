import React from "react";

function WishlistIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 450 450"
            version="1.1"
            viewBox="0 0 450 450"
            xmlSpace="preserve"
            fill="currentColor"
            width="25"
        >
            <path d="M428.9 105c-41.9-70.6-133-94.4-204-53.5-58.7-33.6-132.7-23.9-180.7 24C15.7 103.9.1 141.6 0 181.7S15.5 259.6 43.9 288l94.7 94.7c22.6 22.7 54 35.7 86.2 35.7h.4c32.5 0 63-12.7 86.1-35.7L406 288c28.3-28.4 44-66.2 44-107.6-.2-26.4-7.5-52.6-21.1-75.4zM150.5 78.2c18.5 0 36.7 5 52.6 14.4 13.5 8 30.3 8 43.8 0 16-9.5 34.3-14.5 52.9-14.5 57.2 0 103.7 46.5 103.7 104.7-.3 27.3-11.1 52.9-30.4 72.3l-94.7 94.7c-29.5 29.4-77.4 29.4-106.8 0l-94.7-94.7c-19.6-19.6-30.4-45.7-30.3-73.4 0-57.2 46.5-103.6 103.7-103.6h.1v-6.8h.1v6.8c-.1.1-.1.1 0 .1z"></path>
        </svg>
    );
}

export default WishlistIcon;
