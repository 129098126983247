import React from "react";

const CartIcon = ({ ...props }) => (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 450 450"
            version="1.1"
            viewBox="0 0 450 450"
            xmlSpace="preserve"
            fill="currentColor"
            width="25"
            // height="21.32"
        >
            <path d="M428.2 136.7c-12.8-15.6-31.7-24.7-51.9-25h-16.9C346.6 40.6 279.6-8.4 207.4 1.2c-58.3 8-105 53-115.3 110.5H75.2h-.1c-18.2 0-35.4 7.1-48.3 20-12.9 12.9-20 30.1-20.1 48.3 0 3.5.3 6.9.8 10.5l25.4 164C41.4 409 89.1 450 144.3 450h161.3c55.1 0 103-41 111.3-95.5l25.4-163.9c3.1-19.1-2-38.8-14.1-53.9zm-73.7 247.4c-12.3 13-29.3 20.5-47.6 20.5H146.2h-.1c-18.3 0-35.4-7.5-47.7-20.5h256.1zM98.1 197.6c4.2 4.6 10 7.3 16.3 7.5 6.2.2 12.2-1.9 16.8-6.2 4.6-4.2 7.3-10 7.5-16.2v-23.2h177.6v21.2c-.3 6.3 1.9 12.2 6.2 16.8 4.2 4.6 10 7.3 16.3 7.5 6.3.2 12.2-1.9 16.8-6.2 4.6-4.2 7.3-10 7.5-16.2v-23.2h14c6.3.1 12.2 2.8 16.2 7.4 2.9 3.6 4.4 8 4.5 12.4L373.7 337H79L55.6 184c-1.1-6.1.7-12.4 4.8-17.1 4.3-5.1 10.7-7.8 17.5-7.6h14v21.4c-.2 6.4 2 12.3 6.2 16.9zm42.9-84.9c10.3-38.3 45-65.3 85.3-65.3h.2c40.3 0 75 27 85.3 65.3H141z"></path>
        </svg>
)

export default CartIcon;
