import * as React from "react";
export interface IAppConfig {
    storeCode: string,
    locale: string,
    baseUrl: string,
    logoBaseUrl: string,
    catalogUrlSuffix: string,
    cssThemeClass: string,
    beforeCmsBlock: string,
    afterCmsBlock: string,
    messageCmsBlock: string,
    hasHiddenWishlist: boolean,
    hasHiddenCart: boolean,
    hasHiddenRegistration: boolean,
    context: string
}
interface IContextProps {
    config: IAppConfig
}
export const AppConfigContext = React.createContext<IContextProps>({} as IContextProps);

interface IContextProviderProps {
    config: IAppConfig;
    children: React.ReactNode;
}

const AppConfigProvider = (props: IContextProviderProps) => {
    const currentConfig = props.config;

    return (
        <AppConfigContext.Provider
            value={{
                config: currentConfig
            }}
        >
            {props.children}
        </AppConfigContext.Provider>
    );
}

export default AppConfigProvider;
