import React from "react";

function Extr4WishlistIcon() {
    return (
        <svg
            id="Livello_2"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 28 24"
            xmlSpace="preserve"
            width="24"
            height="24"
        >   
            <path d="M25.3633 3.69727C28.6855 6.54492 28.8438 11.6074 25.8906 14.666L15.6602 25.2129C15.3438 25.5293 14.9219 25.6875 14.4473 25.6875C14.0254 25.6875 13.6035 25.5293 13.2871 25.2129L3.05664 14.666C0.103516 11.6074 0.261719 6.54492 3.58398 3.69727C6.95898 0.849609 11.2832 2.2207 13.4453 4.43555L14.5 5.49023L15.502 4.43555C18.1914 1.64062 22.4629 1.27148 25.3633 3.69727ZM24.6777 13.5059C26.6816 11.3965 27.1035 7.44141 24.2559 5.01562C21.3555 2.53711 17.9277 4.33008 16.7148 5.5957L14.5 7.91602L12.2324 5.5957C10.9668 4.33008 7.5918 2.53711 4.69141 5.01562C1.84375 7.44141 2.26562 11.3965 4.26953 13.5059L14.5 24.0527L24.6777 13.5059Z" fill="black"/>
        </svg>
    );
}

export default Extr4WishlistIcon;
