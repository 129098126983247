import React from "react";

function Extr4SearchIcon() {
    return (
        <svg
            id="Livello_2"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 23.5 23.5"
            xmlSpace="preserve"
            width="24"
            height="24"
        >
             <defs><clipPath id="clippath"><rect className="cls-2" fill="none" stroke-width="0px" width="23.5" height="23.5"/></clipPath></defs><g id="Livello_1-2"><g id="Raggruppa_3935"><g className="cls-3" clip-path="url(#clippath)"><g id="Raggruppa_3934"><path id="Tracciato_3303" className="cls-1" fill="#000" stroke-width="0px" d="M23.5,22.68l-7.12-7.12c3.42-3.88,3.05-9.79-.83-13.21C11.68-1.08,5.76-.71,2.34,3.17c-3.42,3.88-3.05,9.79.83,13.21,3.54,3.12,8.85,3.12,12.39,0l7.13,7.12.82-.82ZM9.4,17.56c-4.5,0-8.15-3.65-8.15-8.16,0-4.5,3.65-8.15,8.16-8.15,4.5,0,8.15,3.65,8.15,8.15,0,4.5-3.65,8.16-8.15,8.16h0Z"/></g></g></g></g>
        </svg>
    );
}

export default Extr4SearchIcon;
