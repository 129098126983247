/*
 * Copyright © MageSpecialist - Skeeller srl. All rights reserved.
 * See COPYING.txt for license details.
 */
const pES = {
    'Hi %1': 'Hi %1',
    'Sign in | Register': 'Iniciar sesión | Regístrate',
    'Sign in': 'Iniciar sesión',
    'Register': 'Regístrate',
    'Start your search': 'Comienza tu búsqueda',
    'Search ...': 'Buscar ...',
};

export default pES;
