import React from "react";

function SearchIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 450 450"
            version="1.1"
            viewBox="0 0 450 450"
            xmlSpace="preserve"
            fill="currentColor"
            width="25"
        >
            <path d="M443.7 410.5l-4.9 4.7h-.1l4.8-4.8-76.2-76.2c29-36.3 44.9-81.5 44.9-128C412.2 92.7 319.8.3 206.3.3h-.3C92.5.2.2 92.4 0 205.8c-.2 113.5 92.1 206 205.6 206.2h.3c46.6 0 91.9-15.9 128.3-44.9l76.4 76.4c4.4 4.1 10 6.3 16 6.3h.8c6.2-.2 12-2.8 16.3-7.4 8.4-8.9 8.4-23 0-31.9zm-78.4-204.4c0 87.7-71.4 159.1-159.1 159.1S47.1 293.8 47.1 206.1C47.1 118.4 118.5 47 206.2 47c87.7 0 159.1 71.4 159.1 159.1z"></path>
        </svg>
    );
}

export default SearchIcon;
