const initializeAccordion = () => {
    const parents = document.querySelectorAll('.parent');

    parents.forEach(function(parent) {
        const div = parent.querySelector(':scope > div');

        div && div.addEventListener('click', function(event) {
            if (!parent.classList.contains('level2')) {
                parents.forEach(function(sibling) {
                    if (sibling !== parent) {
                        sibling.classList.remove('open');
                    }
                });
            }

            parent.classList.toggle('open');
            event.stopPropagation();
        });
    });
}

export default initializeAccordion;
