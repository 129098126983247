/*
 * Copyright © MageSpecialist - Skeeller srl. All rights reserved.
 * See COPYING.txt for license details.
 */
const pIT = {
    'Hi %1': 'Ciao %1',
    'Sign in | Register': 'Accedi | Registrati',
    'Sign in': 'Accedi',
    'Register': 'Registrati',
    'Start your search': 'Inizia la tua ricerca',
    'Search ...': 'Cerca prodotto ...',
};

export default pIT;
