interface ILocalizationCollection {[key: string]: string}

const linksHreflang = Array.from(document.querySelectorAll("link[rel=alternate]"))
    .reduce(
        (acc , e) => {
            const k = e.getAttribute('hreflang') || '';
            const key = k === 'x-default' ? k : k.substring(0,2);
            const v = e.getAttribute('href') || '';

            acc[key] = v;

            return acc;
        }
        ,{} as ILocalizationCollection);

const storesList = ['it','es','de','en','ru','fr'];

const languageMap: ILocalizationCollection = storesList.reduce((acc , e) => {
    acc[e] = linksHreflang[e] || linksHreflang['x-default'];

    return acc;
}, {} as ILocalizationCollection);
const useLocalization = ():ILocalizationCollection => {

    return languageMap;
};

export default useLocalization;
