const configUrl = {
    account: 'customer/account',
    accountLogin: 'customer/account',
    accountCreate: 'customer/account/create',
    cart: 'checkout/cart/index',
    wishlist: 'wishlist',
    search: 'catalogsearch/result/?q='
};

export default configUrl;
